<script lang="ts" setup>
import { Thumbs } from 'swiper/modules'

defineProps<{
  content: object
}>()

const isLoaded = ref(false)
const activeIndex = ref(0)
const swiper = ref()

function onSwiper(swiperElement) {
  isLoaded.value = true
  swiper.value = swiperElement
}

function onSwiperThumb(swiperElement) {
  swiperThumb.value = swiperElement
}

function move(e) {
  activeIndex.value = e.activeIndex

  const x = document
    .querySelectorAll('.thumb-item')
    [e.activeIndex].getBoundingClientRect().left

  document.querySelector('.thumbs').scrollTo({ left: x, behavior: 'smooth' })
}

function setSlide(index) {
  swiper.value.slideTo(index)
}
</script>

<template>
  <section class="!mb-50 overflow-hidden first:mt-100">
    <UContainer>
      <h2 class="text-h3 text-primary mb-24">{{ content.title }}</h2>
      <div class="h-2 w-62 bg-secondary-light"></div>
      <div class="mt-32 lg:mt-40" :class="{ 'opacity-0': !isLoaded }">
        <div class="no-scrollbar thumbs flex w-full gap-20 overflow-auto">
          <div
            class="flex-[0_0_auto] lg:flex-1"
            :key="index"
            v-for="(item, index) in content.tabs"
          >
            <button
              @click="setSlide(index)"
              type="button"
              :class="{ 'tab-active': index === activeIndex }"
              class="thumb-item text-primary block w-full border-b border-transparent pb-24 text-center text-20 leading-none hover:text-secondary-light"
            >
              {{ item.title }}
            </button>
          </div>
        </div>
        <div class="mt-40">
          <Swiper
            :modules="[Thumbs]"
            class="relative !overflow-visible"
            :slides-per-view="1"
            space-between="20"
            @swiper="onSwiper"
            @slideChange="move"
          >
            <SwiperSlide class="!h-auto py-14" v-for="item in content.tabs">
              <div
                class="flex h-full rounded-10 border border-[#DFDFDF] shadow-[0_4px_14px_rgba(0,0,0,0.15)] max-lg:flex-col"
              >
                <div
                  class="invest-title flex flex-col justify-center p-20 text-center max-lg:h-160 lg:w-4/12 lg:p-60 lg:pr-90"
                >
                  <p
                    class="text-h4 pb-24 tracking-[-0.48px] text-white lg:!text-32 lg:!tracking-[-0.64px]"
                  >
                    {{ item.title }}
                  </p>
                  <div class="mx-auto h-2 w-62 bg-secondary-light"></div>
                </div>
                <div
                  class="relative mx-auto flex max-w-[600px] flex-col px-20 py-50 max-lg:flex-1 lg:w-8/12 lg:p-40"
                >
                  <h3
                    v-if="item.subtitle"
                    class="text-primary text-h5 text-center lg:!text-30 lg:!font-semibold lg:leading-45"
                  >
                    {{ item.subtitle }}
                  </h3>
                  <div class="mb-50">
                    <p
                      class="text-primary mt-50 pb-6 text-center font-montserrat text-16 font-semibold italic leading-20 tracking-[-0.32px] lg:text-18 lg:leading-22 lg:tracking-[0.36px]"
                      v-if="item.citation"
                    >
                      <span class="text-secondary-light">“</span>
                      {{ item.citation }}
                      <span class="text-secondary-light">”</span>
                    </p>
                    <div class="mx-auto h-2 w-62 bg-secondary-light"></div>
                    <p
                      v-if="item.infos"
                      class="text-primary mt-6 text-center text-14 leading-21"
                    >
                      {{ item.infos }}
                    </p>
                  </div>
                  <UButton
                    class="mt-auto max-lg:w-full lg:ml-auto"
                    color="secondaryLight"
                    variant="outline"
                    :label="$t('common.more')"
                    :to="item.link"
                  />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </UContainer>
  </section>
</template>

<style lang="scss" scoped>
.invest-title {
  background-image: url('@/assets/images/invest-bg-mobile.svg');
  background-position: bottom right;
  background-repeat: no-repeat;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  @media (min-width: 1280px) {
    border-top-right-radius: 0;
    border-bottom-left-radius: 10px;
    background-image: url('@/assets/images/invest-bg.svg');
  }
}

.tab-active {
  @apply border-secondary-light text-secondary-light;
}
</style>
